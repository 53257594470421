import "./TopBar.scss";
import AccountMenu from "./AccountMenu";
import resumeLogo from "../public/logo_with_text.png";


export function TopBar() {

    return <div className={"top-bar"} data-testid={"top-bar"}>
        <div className={"logo-area"}>
            <img src={resumeLogo} className={"logo"} />
        </div>
        <AccountMenu/>
    </div>
}

export default TopBar;
